/* webpackChunkName: "Carousel" */

import React, { useCallback, useEffect, useState } from 'react'
import { carousel, currentSlideClass, nextSlideClass, slideItems } from "./index.module.scss"

// TODO: use another carousel component

const Carousel = ({ slides=[] }) => {
  const animationDuration = 750;
  const slideDuration = 2000 + animationDuration;

  const [{ sliding, currentIndex }, setState] = useState({
    sliding: false,
    currentIndex: 0
  })

  const currentSlide = slides[currentIndex]
  const nextIndex = currentIndex === (slides.length - 1) ? 0 : currentIndex + 1
  const nextSlide = slides[nextIndex]

  const slide = useCallback(() => {
    setState({ sliding: true, currentIndex })

    setTimeout(() => {
      setState({ sliding: false, currentIndex: nextIndex })
    }, animationDuration)
  }, [currentIndex, nextIndex])

  useEffect(() => {
    const timeout = setTimeout(slide, slideDuration)
    return () => clearTimeout(timeout);
  }, [currentIndex, slideDuration, slide]);

  if (slides.length === 0) return null

  return (
    <div className={carousel}>
      <div className={slideItems}>
        <div key={currentIndex}
          className={currentSlideClass}
          data-sliding={sliding}
        >
          {currentSlide}
        </div>
        {( sliding && (
          <div key={nextIndex} className={nextSlideClass}>
            {nextSlide}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Carousel
