/* webpackChunkName: "Photos" */

import { photosClass, wrapperClass } from "./index.module.scss"

import Carousel from '../carousel/index'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { graphql } from 'gatsby'

const Photos = ({ id, photos, displayAsCarousel=false, inContainer, ratio=56.25 }) => {
  if (photos === null) return null

  const items = photos.map(photo => {
    const image = ratio === 45 ? photo?.high : photo?.small

    return (
      <GatsbyImage
        image={image?.childImageSharp?.gatsbyImageData}
        alt={photo.description}
      />
    )
  })

  if (displayAsCarousel) {
    return <Carousel slides={items}/>
  }

  return (
    <section id={id}>
      <div className={wrapperClass} data-container={inContainer}>
        <div className={photosClass}>
          {items}
        </div>
      </div>
    </section>
  )
}

export default Photos

export const query  = graphql`
  fragment Photos on ContentfulPhotos {
    __typename
    title
    contentful_id
    displayAsCarousel
    inContainer
    ratio
    photos {
      title
      description
      contentful_id
      small: localFile {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 2.85
            layout: FULL_WIDTH
            breakpoints: [375, 750, 1080, 1366, 1920, 2732, 3840]
            formats: [AUTO, WEBP, AVIF]
            quality: 75
            transformOptions: {
              cropFocus: CENTER
            }
          )
        }
      }
      high: localFile {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 2.22
            layout: FULL_WIDTH
            breakpoints: [375, 750, 1080, 1366, 1920, 2732, 3840]
            formats: [AUTO, WEBP, AVIF]
            quality: 75
            transformOptions: {
              cropFocus: CENTER
            }
          )
        }
      }
    }
  }
`
